export function removeDiacritics(text) {
    const diacriticsMap = {
        ä: "a",
        ë: "e",
        ï: "i",
        ö: "o",
        ü: "u",
        Ä: "A",
        Ë: "E",
        Ï: "I",
        Ö: "O",
        Ü: "U",
    };

    return text.replace(/[äëïöüÄËÏÖÜ]/g, (match) => diacriticsMap[match]);
}