import { useCallback, useEffect, useMemo, useReducer, useState } from "react";

import { AuthContext } from "./auth-context";
import PropTypes from "prop-types";
import { axiosInstance } from "src/config/axiosInstance";
import { endpoints } from "src/utils/axios";
import { setSession } from "./utils";
import { storageKeys } from "src/utils/sessionStorage";

const initialState = {
    user: null,
    loading: true,
    paymentMethods: [],
};

const reducer = (state, action) => {
    if (action.type === "INITIAL") {
        return {
            loading: false,
            user: action.payload.user,
            /*  role: action.payload.role, */ // Set the user's role during initialization
            paymentMethods: action.payload.paymentMethods, // Set the user's payment methods during initialization
        };
    }
    if (action.type === "LOGIN") {
        return {
            ...state,
            user: action.payload.user,
            /*  role: action.payload.user.role, */ // Set the user's role during login
            paymentMethods: action.payload.paymentMethods, // Set the user's payment methods during login
        };
    }
    if (action.type === "REGISTER") {
        return {
            ...state,
            user: action.payload.user,
        };
    }
    if (action.type === "LOGOUT") {
        return {
            ...state,
            user: null,
        };
    }
    return state;
};

export function AuthProvider({ children }) {
    const initialize = useCallback(async () => {
        try {
            const accessToken = sessionStorage.getItem(storageKeys.TOKEN);
            const userId = sessionStorage.getItem(storageKeys.USER_ID);

            const data = {
                accessToken,
                userId,
            };

            const response = await axiosInstance.post(endpoints.auth.me, data);

            const { user, paymentMethods } = response.data;
            setSession(accessToken, user);

            dispatch({
                type: "INITIAL",
                payload: {
                    user,
                    paymentMethods,
                },
            });
        } catch (error) {
            console.error(error);
            dispatch({
                type: "INITIAL",
                payload: {
                    user: null,
                },
            });
        }
    }, []);

    useEffect(() => {
        initialize();
    }, [initialize]);

    const [state, dispatch] = useReducer(reducer, initialState);

    const login = useCallback(async (email, password) => {
        const data = {
            email,
            password,
        };

        const response = await axiosInstance.post(endpoints.auth.login, data);
        const { company, userSession } = response.data;

        setSession(userSession?.token, userSession);
        sessionStorage.setItem(storageKeys.TOKEN, userSession?.token);
        sessionStorage.setItem(storageKeys.USER_ID, userSession?.userId);
        sessionStorage.setItem(storageKeys.USER_CITY, userSession?.userCity);
        sessionStorage.setItem(storageKeys.USER_LAT, userSession?.userLat);
        sessionStorage.setItem(storageKeys.USER_LONG, userSession?.userLong);
        sessionStorage.setItem(storageKeys.USER_ROLE, userSession?.userRole);
        sessionStorage.setItem(storageKeys.COMPANY_ID, company?.companyId);
        sessionStorage.setItem(storageKeys.CURRENCY, userSession?.currency);
        sessionStorage.setItem(storageKeys.LOGO, company?.avatarUrl);
        sessionStorage.setItem(storageKeys.COMPANY_NAME, company?.companyName);
        sessionStorage.setItem(storageKeys.COMPANY_VAT, company?.vat);
        sessionStorage.setItem(storageKeys.BANK_NAME, company?.bankName);
        sessionStorage.setItem(storageKeys.BANK_ACCOUNT, company?.bankAccount);
        sessionStorage.setItem(storageKeys.SWIFT, company?.swift);
        sessionStorage.setItem(storageKeys.EMAIL, company?.email);
        sessionStorage.setItem(storageKeys.LANGUAGE, userSession?.language);
        if (userSession?.language == "Spanish") {
            sessionStorage.setItem(storageKeys.LANGUAGE, "es");
        }

        if (userSession?.language == "French") {
            sessionStorage.setItem(storageKeys.LANGUAGE, "fr");
        }
        if (userSession?.language == "English") {
            sessionStorage.setItem(storageKeys.LANGUAGE, "en");
        }
        sessionStorage.setItem(
            storageKeys.BANK_ACCOUNT_OWNER,
            company?.bankAccountOwner,
        );
        sessionStorage.setItem(
            storageKeys.COMPANY_PARENT_NAME,
            company?.companyParentName,
        );
        sessionStorage.setItem(
            storageKeys.ONBOARDING_STATUS,
            company?.onboardingStatus,
        );
        sessionStorage.setItem(
            storageKeys.APPLY_TAX,
            userSession?.applicableTax,
        );
        sessionStorage.setItem(
            storageKeys.SUBSCRIPTION_PLAN,
            userSession?.subscriptionPlan,
        );
        sessionStorage.setItem(
            storageKeys.PAYMENT_METHODS,
            userSession?.paymentMethods,
        );
        sessionStorage.setItem(storageKeys.HOTELS_FEE, userSession?.feesHotels);
        sessionStorage.setItem(
            storageKeys.ACTIVITIES_FEE,
            userSession?.fees.activitiesFee,
        );
        sessionStorage.setItem(
            storageKeys.TRANSFERS_FEE,
            userSession?.fees.transfersFee,
        );
        sessionStorage.setItem(storageKeys.CARS_FEE, userSession?.feesCars);
        sessionStorage.setItem(
            storageKeys.FLIGTHS_FEE,
            userSession?.fees.flightsFee,
        );
        sessionStorage.setItem(
            storageKeys.PACKAGE_FEE,
            userSession?.fees.packagesFee,
        );
        //console.log("userSession*********", userSession);
        console.log(sessionStorage.getItem(storageKeys.PACKAGE_FEE));
        console.log(sessionStorage.getItem(storageKeys.FLIGTHS_FEE));
        console.log(sessionStorage.getItem(storageKeys.TRANSFERS_FEE));
        console.log(storageKeys.PACKAGE_FEE);

        dispatch({
            type: "LOGIN",
            payload: {
                user: userSession,
            },
        });
    }, []);

    const register = useCallback(
        async (email, password, firstName, lastName) => {
            const data = {
                email,
                password,
                firstName,
                lastName,
            };

            const response = await axiosInstance.post(
                endpoints.auth.register,
                data,
            );

            const { accessToken, user } = response.data;

            sessionStorage.setItem(storageKeys.TOKEN, accessToken);

            dispatch({
                type: "REGISTER",
                payload: {
                    user,
                },
            });
        },
        [],
    );

    const logout = useCallback(async () => {
        setSession(null);
        dispatch({
            type: "LOGOUT",
        });
    }, []);

    const checkAuthenticated = sessionStorage.getItem(storageKeys.TOKEN)
        ? "authenticated"
        : "unauthenticated";

    const status = state.loading ? "loading" : checkAuthenticated;

    const [paymentMethod, setPaymentMethod] = useState(null);

    const [userInfo, setUserInfo] = useState(null);

    const memoizedValue = useMemo(
        () => ({
            user: state.user,
            paymentMethods: state.paymentMethods,
            method: "jwt",
            loading: status === "loading",
            authenticated: status === "authenticated",
            unauthenticated: status === "unauthenticated",
            //
            login,
            register,
            logout,
            paymentMethod,
            setPaymentMethod,
            userInfo,
            setUserInfo,
        }),
        [
            login,
            logout,
            register,
            state.user,
            state.paymentMethods,
            status,
            paymentMethod,
            setPaymentMethod,
            userInfo,
            setUserInfo,
        ],
    );

    return (
        <AuthContext.Provider value={memoizedValue}>
            {children}
        </AuthContext.Provider>
    );
}

AuthProvider.propTypes = {
    children: PropTypes.node,
};
