import numeral from "numeral";

// ----------------------------------------------------------------------

export function fNumber(number) {
    return numeral(number).format();
}

export function fCurrency(number, currencyCode) {
    currencyCode = currencyCode || sessionStorage.getItem("currency");

    return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: currencyCode,
        
    }).format(number); // [GO] antes no devolvia el simbolo de euro
}
export function fCurrencyWithoutSign (number, currencyCode) {
  currencyCode ??= sessionStorage.getItem("currency");

  return new Intl.NumberFormat("en-US", {
      maximumFractionDigits: 2, 
      currency: currencyCode,
      
  }).format(number);
}
export function fCurrencyAsCode(number, currencyCode) {
  currencyCode = currencyCode || sessionStorage.getItem("currency");

  return `${new Intl.NumberFormat("en-US", {
      maximumFractionDigits: 2,
      currency: currencyCode,
  }).format(number)}${currencyCode}`;
}

function getFormatString(currencyCode) {
    switch (currencyCode) {
        case "USD":
            return "$0,0.00";
        case "EUR":
            return "€0,0.00";
        case "GBP":
            return "0,0.00 £";
        default:
            return "$0,0.00";
    }
}

export function fPercent(number) {
    const format = number ? numeral(Number(number) / 100).format("0.0%") : "";

    return result(format, ".0");
}

export function fShortenNumber(number) {
    const format = number ? numeral(number).format("0.00a") : "";

    return result(format, ".00");
}

export function fData(number) {
    const format = number ? numeral(number).format("0.0 b") : "";

    return result(format, ".0");
}

function result(format, key = ".00") {
    const isInteger = format.includes(key);

    return isInteger ? format.replace(key, "") : format;
}
