import { useTranslation } from "react-i18next";
import { useCallback, useEffect } from "react";
// utils
import { localStorageGetItem } from "src/utils/storage-available";
// components
import { useSettingsContext } from "src/components/settings";
//
import { allLangs, defaultLang } from "./config-lang";

// ----------------------------------------------------------------------

export default function useLocales() {
    const { i18n, t } = useTranslation();
    const settings = useSettingsContext();

    const searchParams = new URLSearchParams(window.location.search);
    const urlLang = searchParams.get("lang");

    const langStorage = localStorageGetItem("i18nextLng");

    const currentLang =
        allLangs.find((lang) => lang.value === urlLang) ||
        allLangs.find((lang) => lang.value === langStorage) ||
        defaultLang;

    useEffect(() => {
        if (urlLang && urlLang !== i18n.language) {
            onChangeLang(urlLang);
        }
    }, [urlLang, i18n.language]);

    const onChangeLang = useCallback(
        (newLang) => {
            i18n.changeLanguage(newLang);
            settings.onChangeDirectionByLang(newLang);
        },
        [i18n, settings]
    );

    return {
        allLangs,
        t,
        currentLang,
        onChangeLang,
    };
}
