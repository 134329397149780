import { axiosInstance } from "src/config/axiosInstance";

class ItineraryService {
    // static async updateTask(taskId, updateData) {
    //   try {
    //     const response = await axiosInstance.put(
    //       `/tasks/update/${taskId}`,
    //       updateData
    //     );
    //     return response.data;
    //   } catch (error) {
    //     console.error("An error occurred while updating the task:", error);
    //     throw error;
    //   }
    // }
    static async updateItineraryProducts(itineraryId, updateData) {
        try {
            const response = await axiosInstance.put(
                `/itineraries/products/edit/${itineraryId}`,
                updateData,
            );
            return response.data;
        } catch (error) {
            console.error(
                "An error occurred while updating the Itinerary:",
                error,
            );
            throw error;
        }
    }

    static async updateItineraryHotelSelected(itineraryId, updateData) {
        try {
            const response = await axiosInstance.put(
                `/itineraries/hotelselected/edit/${itineraryId}`,
                updateData,
            );
            return response.data;
        } catch (error) {
            console.error(
                "An error occurred while updating the Itinerary:",
                error,
            );
            throw error;
        }
    }

    static async updateItinerary(itineraryId, updateData) {
        try {
            const response = await axiosInstance.put(
                `/itineraries/edit/${itineraryId}`,
                updateData,
            );
            return response.data;
        } catch (error) {
            console.error(
                "An error occurred while updating the Itinerary:",
                error,
            );
            throw error;
        }
    }

    static async isItineraryCreated(projectId, maxRetries, retryDelay) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.get(
                    `/isProjectItineraryCreated/${projectId}`,
                );

                return response;
            } catch (error) {
                console.error("Error fetching itinerary details:", error);
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
    }

    static async setSelectedTicket(itineraryId, productCode, selectedTicket) {
        try {
            const response = await axiosInstance.post(
                `/itineraries/${itineraryId}/product/${productCode}/setSelectedTicket`,
                selectedTicket,
            );

            return response;
        } catch (error) {
            console.error("An error occurred:", error);
            throw error;
        }
    }

    static async setSelectedRoomRate(
        itineraryId,
        hotelCode,
        selectedRoom,
        selectedRate,
    ) {
        const data = {
            room: selectedRoom,
            roomRate: selectedRate,
        };
        try {
            const response = await axiosInstance.post(
                `/itineraries/${itineraryId}/hotel/${hotelCode}/setSelectedRoomRate`,
                data,
            );

            return response;
        } catch (error) {
            console.error("An error occurred:", error);
            throw error;
        }
    }

    static async updateProductManually(updateData) {
        try {
            const response = await axiosInstance.post(
                `/itineraries/updatePriceActivityManualItinerary`,
                updateData,
            );
            return response.data;
        } catch (error) {
            console.error(
                "An error occurred while updating the Product:",
                error,
            );
            return { error: true };
        }
    }

    static async getItineraryFlightsOffers(
        projectId,
        currentCity,
        isFlightsPackage,
    ) {
        try {
            const response = await axiosInstance.post(
                `/flights/itineraryOffers`,
                {
                    projectId,
                    currentCity,
                    isFlightsPackage,
                },
            );

            return response.data;
        } catch (error) {
            console.error(
                "An error occurred while fetching the flights offers:",
                error,
            );
            throw error;
        }
    }

    static async setNewSelectedFlight(
        projectId,
        itineraryId,
        oldFlightData,
        newFlightData,
    ) {
        try {
            const response = await axiosInstance.put(
                `/itineraries/flights/edit/${itineraryId}`,
                {
                    oldFlightData,
                    newFlightData,
                    projectId,
                },
            );

            return response.data;
        } catch (error) {
            console.error(
                "An error occurred while setting the new selected flight:",
                error,
            );
            throw error;
        }
    }

    static async updateTransferFlightChange(
        itineraryId,
        itineraryIdWithFlights,
        updatedFlight,
    ) {
        try {
            const response = await axiosInstance.put(
                `/itineraries/updateTransferFlightChange/${itineraryId}`,
                {
                    itineraryIdWithFlights,
                    updatedFlight,
                },
            );

            return response.data;
        } catch (error) {
            console.error(
                "An error occurred while updating the transfer flight change:",
                error,
            );
            throw error;
        }
    }

    static async deleteItineraryFlights(itineraryId, flightData) {
        try {
            const response = await axiosInstance.put(
                `/itineraries/${itineraryId}/remove/flight`,
                flightData,
            );

            return response.data;
        } catch (error) {
            console.error(
                "An error occurred while deleting the itinerary flights:",
                error,
            );
            throw error;
        }
    }

    static async addManualFlight(itineraryId, flightData) {
        try {
            const response = await axiosInstance.put(
                `/itineraries/${itineraryId}/add/manual/flight`,
                { flightData },
            );

            return response.data;
        } catch (error) {
            console.error(
                "An error occurred while adding the manual flight:",
                error,
            );
        }
    }

    static async updateManualFlight(itineraryId, flightData) {
        try{

            const response=await axiosInstance.put(
                `/itineraries/${itineraryId}/update/flight`,
                {...flightData}
            )

            return response.data;

        }catch(error){
            console.error(
                "An error occurred while updating the manual flight:",
                error,
            );
            console.log(error);
        }
    }

    static async updateOptionalsHotels(itineraryId , hotelCode ){
        try{

            const response=await axiosInstance.put(
                `itineraries/${itineraryId}/update/optionalHotel`,
                {hotelCode}
            )

            return response.data;

        }catch(error){
            console.error(
                "An error occurred while updating the optionals hotels:",
                error,
            );
            throw error;
        }
    }
}

export default ItineraryService;
