import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { axiosInstance } from "src/config/axiosInstance";
import { AuthContext } from "src/auth/context/jwt";
import { endpoints } from "src/utils/axios";
import { storageKeys } from "src/utils/sessionStorage";
import { ExchangeRateContext } from "./context";

const SPECIAL_TOKEN = process.env.REACT_APP_TZ_API_KEY;
const TIME_LAPSE = 600000; // 10 minutes

export function ExchangeRateProvider({ children }) {
    const timeRef = useRef(null);
    const { authenticated } = useContext(AuthContext);
    const [exchangeRate, setExchangeRate] = useState({
        currency: "EUR",
        rate: 1,
    });
    const updateExchangeRate = useCallback(async (projectId,forceUpdate=false) => {
        try {
            const accessToken = sessionStorage.getItem(storageKeys.TOKEN);
            const userId = sessionStorage.getItem(storageKeys.USER_ID);

            if (!projectId && (!userId || !accessToken)) {
              return
            };

            const now = Date.now();
            if (!forceUpdate && timeRef.current && now - timeRef.current < TIME_LAPSE) {
                // The function was executed less than 10 minutes ago
                return;
            }
            timeRef.current = now;
            const data = projectId
                ? { projectId }
                : {
                      accessToken,
                      userId,
                  };

            const response = await axiosInstance.post(
                endpoints.auth.exchangeRate,
                data,
                { headers: { "tz-api-key": SPECIAL_TOKEN } },
            );
            setExchangeRate(response.data);
        } catch (error) {
            console.log(error);
            timeRef.current = null;
        }
    }, []);

    useEffect(() => {
        if (!authenticated) return;
        updateExchangeRate();
    }, [authenticated, updateExchangeRate]);

    return (
        <ExchangeRateContext.Provider
            value={{
                exchangeRate,
                setExchangeRate,
                updateExchangeRate,
            }}
        >
            {children}
        </ExchangeRateContext.Provider>
    );
}
