import { axiosInstance } from "src/config/axiosInstance";

class CompanyService {
    static async fetchCompanyUsersList(companyId, maxRetries, retryDelay) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.get(`/users/${companyId}`);
                return response;
            } catch (error) {
                console.error("An error occurred:", error);
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
        return [];
    }

    static async fetchCompanyDetails(companyId, maxRetries, retryDelay) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.get(
                    `/companies/${companyId}`,
                );
                return response;
            } catch (error) {
                console.error("Error fetching project details:", error);
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
    }

    static async fetchCompanyChilds(companyId) {
        try {
            const response = await axiosInstance.get(
                `/companies/childs/${companyId}`,
            );
            return response?.data?.companies;
        } catch (error) {
            console.error("Error fetching company childs:", error);
        }
    }

    static async validateCompanyEmail(email) {
        try {
            const response = await axiosInstance.get(
                `/companies/validate/email?email=${email}`,
            );

            return response?.data?.isValid;
        } catch (error) {
            console.error("Error validating company email:", error);
        }
    }

    static async createCompany(data) {
        try {
            const response = await axiosInstance.post(
                "/companies/create",
                data,
            );

            return response?.data?.company;
        } catch (error) {
            console.error("Error creating company:", error);
        }
    }

    static async getParentCompanies() {
        try {
            const response = await axiosInstance.get("/companies/parents/name");

            return response?.data?.companies;
        } catch (error) {
            console.error("Error in get parent companies");
        }
    }

    static async updateOnboardingStatus(companyId, step) {
        const data = {
            companyId,
            step,
        };
        try {
            const response = await axiosInstance.post(
                "/companies/updateOnboardingStatus",
                data,
            );

            return response?.data;
        } catch (error) {
            console.error("Error updating company:", error);
        }
    }

    static async updateSMTPDetails(data) {
        try {
            const response = await axiosInstance.post(
                "/companies/updateSMTPDetails",
                data,
            );

            return response?.data;
        } catch (error) {
            console.error("Error updating company:", error);
        }
    }

    static async validateVat(vat) {

        try{

            const response = await axiosInstance.get(`/companies/validate/vat?vat=${vat}`);
            return response?.data?.isValid;

        }catch(error){
            console.error("Error validating vat:", error);
        }

    }
}

export default CompanyService;
