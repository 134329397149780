export const MAX_HOTEL_ROOMS = 4;
export const MAX_HOTEL_ADULTS = 6;
export const MAX_HOTEL_KIDS = 4;

export const DEFAULT_ROOM_SEARCH = [
    {
        adults: 2,
        kids: 0,
        /**
         * @type {number[]}
         */
        childrenAges: [],
    },
];

/**
 * @typedef {Object} Guest
 * @property {"Mr" | "Ms" | "Mrs"} title
 * @property {string} firstName
 * @property {string} lastName
 * @property {"Adult" | "Child"} type
 */
/**
 * @typedef {Object} RoomQuery
 * @property {string} adults
 * @property {string} kids
 * @property {string[]} childrenAges
 * @property {Guest[]} guests
 */

/**
 * @type {RoomQuery[]}
 */
export const DEFAULT_ROOMS_QUERY = [
    {
        adults: "2",
        kids: "0",
        childrenAges: [],
        guests: [],
    },
];
