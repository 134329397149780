import {
    HOST_API,
    HOST_API_CRUISES,
    HOST_API_FLIGHTS,
} from "src/config-global";
import axios, { CanceledError } from "axios";

import qs from "qs";
import { storageKeys } from "src/utils";

//timeout up to 3 minutes for hotelbeds and TBO requirements
const axiosInstance = axios.create({
    baseURL: HOST_API,
    timeout: 180000,
});

axiosInstance.interceptors.request.use(
    (config) => {
        const token = sessionStorage.getItem(storageKeys.TOKEN);
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => Promise.reject(new Error(error)),
);

axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error instanceof CanceledError) {
            return Promise.reject(error);
        }
        return Promise.reject(
            (error.response && error.response.data) || "Something went wrong",
        );
    },
);

const flightAPI = axios.create({
    baseURL: HOST_API_FLIGHTS,
});

flightAPI.interceptors.response.use(
    (res) => res,
    (err) =>
        Promise.reject(
            (err.response && err.response.data) || "Something went wrong",
        ),
);

const cruiseAPI = axios.create({
    baseURL: HOST_API_CRUISES,
    paramsSerializer: (params) =>
        qs.stringify(params, { arrayFormat: "brackets" }),
});

cruiseAPI.interceptors.request.use(
    (config) => {
        const token = sessionStorage.getItem(storageKeys.TOKEN);
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => Promise.reject(error),
);

cruiseAPI.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error instanceof CanceledError) {
            return Promise.reject(error);
        }
        return Promise.reject(
            (error.response && error.response.data) || "Something went wrong",
        );
    },
);

export { axiosInstance, flightAPI, cruiseAPI };

export default axiosInstance;
