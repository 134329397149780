import { axiosInstance } from "src/config/axiosInstance";

class UsersService {
    static async fetchUsersSummary(companyId, maxRetries, retryDelay) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.get(
                    `/users/summary/${companyId}`,
                );

                return response;
            } catch (error) {
                console.error("An error occurred:", error);
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
        return [];
    }

    static async fetchUserDetails(projectId, maxRetries, retryDelay) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.get(
                    `/users/user/${projectId}`,
                );

                return response;
            } catch (error) {
                console.error("Error fetching project details:", error);
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
    }

    static async DeleteUserCompany(userId){
        try{

            const response = await axiosInstance.delete(`/users/delete/${userId}`);

            return response;

        }catch(error){
            console.error(error);
        }
    }

    static async CreateUser(data){
        try{

            const response = await axiosInstance.post(`/users/new`, data);

            return response;

        }catch(error){
            console.error(error)
            throw error;
        }
    }
    
    static async verifyUserEmail(email){
        try{

            const response = await axiosInstance.get(`/users/email/validate?email=${email}`);

            return response?.data;

        }catch(error){
            console.error(error);
            throw error;
        }
    }
    static async fetchUserLoginDetails(companyId, maxRetries, retryDelay) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.get(
                    `/users/login/${companyId}`,
                );
                return response;
            } catch (error) {
                console.error("Error fetching company logo details:", error);
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
    }
}

export default UsersService;
